.jdsu__market-os {
  display: -ms-grid;
  display: grid;
  width: 100%;
  -ms-grid-rows: 5em auto 460px 40px;
      grid-template-rows: 5em auto 460px 40px;
      grid-template-areas: "jdsu__market-os__market-navigation" "jdsu__market-os__market-body" "jdsu__market-os__market-footer" "jdsu__market-os__market-copyrights";
}

.jdsu__market-os__market-navigation {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: jdsu__market-os__market-navigation;
}

.jdsu__market-os__market-navigation__home svg {
  height: 50px;
}

.jdsu__market-os__market-navigation__nav-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px;
  margin: 0px;
}

.jdsu__market-os__market-navigation__nav-items ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jdsu__market-os__market-navigation__nav-items li {
  list-style-type: none;
}

.jdsu__market-os__market-navigation__nav-items a {
  text-decoration: none;
}

.jdsu__market-os__market-navigation__tools svg {
  height: 1em;
}

.jdsu__market-os__market-body {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: jdsu__market-os__market-body;
}

.jdsu__market-os__market-footer {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: jdsu__market-os__market-footer;
}

.jdsu__market-os__market-copyrights {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  grid-area: jdsu__market-os__market-copyrights;
}

.jdsu__product-search__search-criteria {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 6fr 1fr;
      grid-template-columns: 1fr 6fr 1fr;
  -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
      grid-template-areas: ". jdsu__product-search__search-criteria__body .";
  width: 100%;
}

.jdsu__product-search__search-criteria__body {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: jdsu__product-search__search-criteria__body;
}

.jdsu__product-search__search-results {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 6fr 1fr;
      grid-template-columns: 1fr 6fr 1fr;
  -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
      grid-template-areas: ". jdsu__product-search__search-results__info ." ". jdsu__product-search__search-results__list-container .";
  width: 100%;
}

.jdsu__product-search__search-results__info {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: jdsu__product-search__search-results__info;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jdsu__product-search__search-results__list-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: jdsu__product-search__search-results__list-container;
  width: 100%;
  padding: 1em;
  grid-gap: 0.5em;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-grid-columns: (minmax(175px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
}

.jdsu__product-search__search-results__list-container a {
  text-decoration: none;
  color: black;
}

.jdsu__product-search__search-results__list-container__list-item {
  cursor: pointer;
  padding: 1em;
}

.jdsu__product-search__search-results__list-container__list-item__tag {
  position: absolute;
  text-transform: uppercase;
  font-size: 0.75em;
  padding: 3px;
  line-height: 0.8em;
}

.jdsu__product-search__search-results__list-container__list-item__pricing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jdsu__product-search__search-results__list-container__list-item__price {
  margin-right: 1em;
}

.jdsu__product-search__search-results__list-container__list-item__relative-price {
  text-decoration: line-through;
  font-size: 0.75em;
}

.jdsu__product-search__search-results__list-container__list-item__showcase {
  position: relative;
  width: 100%;
}

.jdsu__product-search__search-results__list-container__list-item__showcase__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 1.5em;
}

.jdsu__product-search__search-results__list-container__list-item__showcase__inner svg {
  height: 100px;
}

.jdsu__breadcrumbs {
  height: 5em;
}

.jdsu__single-item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 6fr 1fr;
      grid-template-columns: 1fr 6fr 1fr;
      grid-template-areas: ". jdsu__single-item__body .";
}

.jdsu__single-item h4 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.jdsu__single-item__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: jdsu__single-item__body;
}

.jdsu__single-item__body__primary-info {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-column-gap: 6em;
  margin-bottom: 4em;
}

.jdsu__single-item__body__primary-info__media {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 5fr;
      grid-template-columns: 1fr 5fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "jdsu__single-item__body__primary-info__media__selection jdsu__single-item__body__primary-info__media__selected-medium";
}

.jdsu__single-item__body__primary-info__media__selected-medium {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: jdsu__single-item__body__primary-info__media__selected-medium;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jdsu__single-item__body__primary-info__media__selected-medium svg {
  height: 100%;
}

@media only screen and (max-width: 750px) {
  .jdsu__product-search__search-results {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        grid-template-areas: "jdsu__product-search__search-results__info" "jdsu__product-search__search-results__list-container";
    width: 100%;
    padding: 0px 1.5em;
  }
  .jdsu__product-search__search-results__list-container {
    padding: 0px;
  }
  .jdsu__product-search__search-criteria {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    padding: 0px 1.5em;
        grid-template-areas: "jdsu__product-search__search-criteria__body";
  }
}

.dropdown-cart {
  padding: 0.56rem 0.69rem;
}

.dropdown-cart .header {
  font-family: Quicksand, sans-serif;
  font-size: 0.56rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: var(--main-color);
}

.dropdown-cart .item {
  padding-bottom: 0.69rem;
  padding-top: 0.69rem;
  border-width: 0.06rem;
  border-bottom-style: solid;
  border-bottom-color: var(--divider-color);
}

.dropdown-cart .item .icon-container {
  display: inline-block;
  width: 3.94rem;
  height: 3.94rem;
  border: solid 1px #707070;
  background-color: #fbfbfb;
  padding: 0.5rem;
  vertical-align: top;
}

.dropdown-cart .item .icon-container__inner svg {
  height: 3em;
  width: 3em;
}

.dropdown-cart .item .specs-container {
  display: inline-block;
  width: 9.50rem;
  height: 3.94rem;
  margin-left: 0.69rem;
}

.dropdown-cart .item .specs-container .specs {
  height: 2.31rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Quicksand, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.31px;
  text-align: left;
  color: var(--main-color);
}

.dropdown-cart .item .specs-container .price {
  font-family: Quicksand, sans-serif;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.44px;
  text-align: left;
  color: var(--main-color);
}

.dropdown-cart .item .button-container {
  display: inline-block;
  vertical-align: top;
}

.dropdown-cart .item .button-container .remove-button {
  width: 0.69rem;
  height: 0.69rem;
  padding: 0;
  background-color: #fdf9f0;
  color: #707070;
}

.dropdown-cart .item .button-container .remove-button svg {
  margin: auto;
  width: 0.31rem;
  height: 0.31rem;
}

.dropdown-cart .subtotal {
  padding: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-family: Quicksand, sans-serif;
  font-size: 0.88rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.43;
  letter-spacing: -0.36px;
  color: var(--carbonext-main-accent-color);
}

.dropdown-cart .cart-buttons {
  padding-bottom: 1rem;
}

.dropdown-cart .cart-buttons .dropdown-button {
  display: block !important;
  width: 12.75rem;
  margin: 0.3rem auto;
  padding: 0;
}
