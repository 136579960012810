body {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* Assign grid instructions to our parent grid container, mobile-first (hide the sidenav) */
.cloud-management-os-grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 50px 1fr 50px;
      grid-template-rows: 50px 1fr 50px;
      grid-template-areas: "header" "main" "footer";
  height: 100vh;
}

.menu-icon {
  position: fixed;
  /* Needs to stay visible for all mobile scrolling */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 5px;
  left: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #dadae3;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  padding: 12px;
}

/* Give every child element its grid name */
.cloud-management-os-header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: header;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.cloud-management-os-sidenav {
  grid-area: sidenav;
  display: -ms-grid;
  display: grid;
  height: 100%;
  width: 150px;
  position: fixed;
      grid-template-areas: "nav-logo-section" "nav-items" "nav-user";
  -ms-grid-rows: max-content auto max-content;
      grid-template-rows: -webkit-max-content auto -webkit-max-content;
      grid-template-rows: max-content auto max-content;
  -webkit-transform: translateX(-245px);
          transform: translateX(-245px);
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  z-index: 2;
  /* Needs to sit above the hamburger menu icon */
}

.cloud-management-os-sidenav .nav-logo-section {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: nav-logo-section;
  padding: 15px 20px 10px 20px;
}

.cloud-management-os-sidenav .nav-items {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: nav-items;
}

.cloud-management-os-sidenav .nav-items ul {
  margin: 0px;
}

.cloud-management-os-sidenav .nav-user {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: nav-user;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2em 0;
}

.cloud-management-os-sidenav.active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.sidenav__close-icon {
  position: absolute;
  visibility: visible;
  top: 8px;
  right: 12px;
  cursor: pointer;
  font-size: 20px;
  color: #ddd;
}

.sidenav__list {
  padding: 0;
  margin-top: 30px;
  list-style-type: none;
}

.sidenav__list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 20px 20px 20px;
  color: #ddd;
}

.sidenav__list-item svg {
  height: 1.5em;
  margin-right: 0.25em;
}

.sidenav__list-item:hover {
  cursor: pointer;
}

.main {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: main;
  height: 100vh;
}

.main-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 20px;
  padding: 20px;
  height: 150px;
  color: slategray;
}

.main-overview {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(265px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-auto-rows: 94px;
  grid-gap: 20px;
  margin: 20px;
}

.overviewcard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
  background-color: #d3d3;
}

.main-cards {
  -webkit-column-count: 1;
          column-count: 1;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  margin: 20px;
}

.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  background-color: #82bef6;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* Force varying heights to simulate dynamic content */
.card:first-child {
  height: 485px;
}

.card:nth-child(2) {
  height: 200px;
}

x .card:nth-child(3) {
  height: 265px;
}

/* Non-mobile styles, 750px breakpoint */
@media only screen and (min-width: 46.875em) {
  /* Show the sidenav */
  .cloud-management-os-grid-container {
    -ms-grid-columns: 150px 1fr;
        grid-template-columns: 150px 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "sidenav main";
  }
  .cloud-management-os-sidenav {
    position: relative;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    height: 100vh;
  }
  .cloud-management-os-sidenav .submenu {
    position: relative;
  }
  .cloud-management-os-sidenav .submenu:hover ul {
    display: inline-block;
    padding: 0px;
    left: 150px;
  }
  .cloud-management-os-sidenav .submenu ul {
    position: absolute;
    width: 200px;
    top: 0;
    background: #333;
    z-index: 2;
    display: none;
    list-style-type: none;
  }
  .cloud-management-os-sidenav .submenu ul a {
    color: white;
    text-decoration: none;
  }
  .cloud-management-os-sidenav .submenu ul li {
    padding: 10px 10px 10px 20px;
  }
  .sidenav__close-icon {
    visibility: hidden;
  }
}

/* Medium screens breakpoint (1050px) */
@media only screen and (min-width: 65.625em) {
  /* Break out main cards into two columns */
  .main-cards {
    -webkit-column-count: 2;
            column-count: 2;
  }
}

.main__template_1 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20;
      grid-template-columns: 20;
  -ms-grid-rows: 3em max-content 1fr;
      grid-template-rows: 3em -webkit-max-content 1fr;
      grid-template-rows: 3em max-content 1fr;
      grid-template-areas: "main__template_1_header" "main__template_1_aysnc_mutation" "main__template_1_body";
  height: 100%;
}

.main__template_1 .main__template_1_aysnc_mutation {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: main__template_1_aysnc_mutation;
}

.main__template_1 .main__template_1_header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: main__template_1_header;
}

.main__template_1 .main__template_1_header .main__template_1_header_inner_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 10px;
}

.main__template_1 .main__template_1__body {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: main__template_1_body;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
}

.main__template_1__body_template_1 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 50px 1fr;
      grid-template-rows: 50px 1fr;
      grid-template-areas: "main__template_1__body_template_1__header" "main__template_1__body_template_1__body";
  height: 100%;
  width: 100%;
}

.main__template_1__body_template_1 .main__template_1__body_template_1__header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: main__template_1__body_template_1__header;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: max-content 1fr max-content;
      grid-template-columns: -webkit-max-content 1fr -webkit-max-content;
      grid-template-columns: max-content 1fr max-content;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "main__template_1__body_template_1__header__left main__template_1__body_template_1__header__middle main__template_1__body_template_1__header__right";
}

.main__template_1__body_template_1 .main__template_1__body_template_1__header .main__template_1__body_template_1__header__left {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: main__template_1__body_template_1__header__left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 13px 20px 13px 20px;
}

.main__template_1__body_template_1 .main__template_1__body_template_1__header .main__template_1__body_template_1__header__left .main__template_1__body_template_1__header__left_inner_containter {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main__template_1__body_template_1 .main__template_1__body_template_1__header .main__template_1__body_template_1__header__left .main__template_1__body_template_1__header__left_inner_containter svg {
  height: 100%;
}

.main__template_1__body_template_1 .main__template_1__body_template_1__header .main__template_1__body_template_1__header__middle {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: main__template_1__body_template_1__header__middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 13px 20px 13px 20px;
}

.main__template_1__body_template_1 .main__template_1__body_template_1__header .main__template_1__body_template_1__header__middle .main__template_1__body_template_1__header__middle_inner_containter {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.main__template_1__body_template_1 .main__template_1__body_template_1__header .main__template_1__body_template_1__header__middle .main__template_1__body_template_1__header__middle_inner_containter svg {
  height: 100%;
}

.main__template_1__body_template_1 .main__template_1__body_template_1__header .main__template_1__body_template_1__header__right {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: main__template_1__body_template_1__header__right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 13px 20px 13px 20px;
}

.main__template_1__body_template_1 .main__template_1__body_template_1__header .main__template_1__body_template_1__header__right .main__template_1__body_template_1__header__right_inner_containter {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main__template_1__body_template_1 .main__template_1__body_template_1__header .main__template_1__body_template_1__header__right .main__template_1__body_template_1__header__right_inner_containter svg {
  height: 100%;
}

.main__template_1__body_template_1 .main__template_1__body_template_1__body {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: main__template_1__body_template_1__body;
}

.full-body {
  height: 100%;
  width: 100%;
}

.vh-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.search-and-list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 15rem 1fr;
      grid-template-columns: 15rem 1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "search items";
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.search-and-list .search {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: search;
  overflow: scroll;
}

.search-and-list .items {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: items;
  overflow: scroll;
}

.search-and-list .items .items-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1.2em;
}

.main__template_1_body {
  max-height: 100%;
  overflow: hidden;
}

.dropdown {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.dropdown .items-count {
  width: 0.94rem;
  height: 0.94rem;
  margin: 0 0 0.63rem -0.3rem;
  background-color: var(--carbonext-main-accent-color);
  border-radius: 50%;
}

.dropdown .items-count .items-count-text {
  margin: auto;
  width: 0.75rem;
  height: 0.75rem;
  font-family: Quicksand, sans-serif;
  font-size: 0.63rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.right-justified-dropdown {
  right: 0px;
}

.no-hover-dropdown-content {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 2em;
  background-color: var(--main-background-color);
  min-width: 261px;
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.19rem;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.action-menu {
  padding: 0px;
}

.action-menu .action-menu-action-body {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      grid-template-areas: "action-menu-action-body__title" "action-menu-action-body__body";
}

.action-menu .action-menu-action-body__title {
  grid-area: .action-menu .action-menu-action-body__title __title;
}

.action-menu .action-menu-action-body__body {
  grid-area: .action-menu .action-menu-action-body__body __body;
  padding: 5em;
}

.action-menu .action-menu-button-body {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  grid-gap: 15px;
  margin: 15px;
      grid-template-areas: "action-menu-button-container";
}

.action-menu-button-container {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: action-menu-button-container;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  grid-gap: 3px;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
}

.action-menu-button-description {
  grid-area: action-menu-button-description;
}

.action-menu-item {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr 30px;
      grid-template-rows: 1fr 30px;
}

.action-menu-button {
  width: 8em;
  height: 8em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.action-menu-button svg {
  height: 50%;
}

.three_column_header {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: max-content 1fr max-content;
      grid-template-columns: -webkit-max-content 1fr -webkit-max-content;
      grid-template-columns: max-content 1fr max-content;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "three_column_header_left three_column_header_middle three_column_header_right";
  height: 100%;
  width: 100%;
}

.three_column_header .three_column_header_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: three_column_header_left;
}

.three_column_header .three_column_header_middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: three_column_header_middle;
}

.three_column_header .three_column_header_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 15px;
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: three_column_header_right;
}

.three_column_header .three_column_header_right button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.search-inner {
  padding: 15px;
}

.search-inner .text-input {
  margin: 0px;
  border: solid 1px black;
  width: 100%;
}

.model-manager__search-filter-item {
  margin-right: 0.4em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.2em 0.6em;
  font-size: 0.75em;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.model-manager__search-filter-item::last-child {
  margin-right: 0;
}

.model-manager__search-filter-item:after {
  position: relative;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 0.5em;
  content: "X";
  /* use the hex value here... */
  color: black;
  text-align: center;
}

.model-manager-header-selector-item {
  width: 100%;
}

.model-manager-header-selector-item a {
  text-decoration: none;
  font-size: 12px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.state-model-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.state-model-header a {
  text-decoration: none;
}

.state-model-header a:visited {
  text-decoration: none;
  color: black;
}

.model-manager-header-selector__selected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.model-manager-header-selector__selected__text {
  padding-right: 8px;
}

.model-manager-header-selector__section {
  padding: 0px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

.model-manager-header-selector__section a {
  text-decoration: none;
}

.model_manager__toolbar__current_search_param {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.model_manager__toolbar__current_search_param .filtered-attr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 3px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.model_manager__toolbar__current_search_param .filtered-attr:not(:last-child) {
  margin-right: 2px;
}

.load-more {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 16px;
}

.dropdown-menu {
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.dropdown-menu__title {
  padding: 1em 1em 0.25em 1em;
  font-weight: bold;
  position: -webkit-sticky;
  position: sticky;
}

.dropdown-menu__items {
  width: 100%;
  overflow: scroll;
}

.dropdown-menu__items__item {
  font-size: 0.75em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1em 2em;
}

.model-item {
  width: 100%;
  position: relative;
}

.model-item .hidden {
  visibility: hidden;
}

.model-item__actions {
  position: absolute;
  z-index: 2;
  right: 3em;
  top: 1em;
  max-height: calc(100% - 1em);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.model-item__actions svg {
  width: 1.5em;
}

.model-item-style-1 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 10em 15em 1fr 1fr 2em;
      grid-template-columns: 10em 15em 1fr 1fr 2em;
  -ms-grid-rows: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: "model-item-style-1-model-type model-item-style-1-model-section-1 model-item-style-1-model-section-2 model-item-style-1-model-section-3 model-item-style-1-model-actions" "model-item-style-1-model-type model-item-style-1-model-section-1 model-item-style-1-model-section-2 model-item-style-1-model-section-3 model-item-style-1-model-tools" "model-item-style-1-model-type model-item-style-1-model-section-1 model-item-style-1-model-section-2 model-item-style-1-model-section-3 model-item-style-1-model-tools";
  height: 10em;
  width: 100%;
}

.model-item-style-1 .model-item-style-1-model-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  grid-area: model-item-style-1-model-type;
}

.model-item-style-1 .model-item-style-1-model-type svg {
  height: 5em;
  width: 5em;
  stroke: 3px;
}

.model-item-style-1 .model-item-style-1-model-identifier {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: 1em;
  grid-area: model-item-style-1-model-identifier;
}

.model-item-style-1 .model-item-style-1-model-section-1 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 2;
  grid-area: model-item-style-1-model-section-1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.5em 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.model-item-style-1 .model-item-style-1-model-section-1__identifier {
  font-size: 1.25em;
  margin-bottom: 5px;
  font-weight: 500;
}

.model-item-style-1 .model-item-style-1-model-section-1__owner {
  font-size: 1em;
}

.model-item-style-1 .model-item-style-1-model-section-3 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 4;
  grid-area: model-item-style-1-model-section-3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1em;
}

.model-item-style-1 .model-item-style-1-model-section-2 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 3;
  grid-area: model-item-style-1-model-section-2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1.25em 0px;
}

.model-item-style-1 .model-item-style-1-model-actions {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: model-item-style-1-model-actions;
  padding-top: 1em;
}

.model-item-style-1-with-indicator {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1em 1fr;
      grid-template-columns: 1em 1fr;
      grid-template-areas: "model-item-style-1-with-indicator__indicator model-item-style-1-with-indicator_body";
}

.model-header-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 500;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  z-index: 20;
  border-width: 5px;
  border-style: solid;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.right-side-tooltip {
  position: relative;
}

.right-side-tooltip .right-side-tooltip-text {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 110%;
  font-size: 0.5em;
}

.right-side-tooltip .right-side-tooltip-text::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
}

.right-side-tooltip:hover .right-side-tooltip-text {
  visibility: visible;
}

.jdsu__modal {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2000;
  height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.jdsu__modal svg {
  height: 1em;
}

.jdsu__modal__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  border-radius: 6px;
  padding: 5em;
  min-width: 35%;
}
